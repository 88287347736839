import localee32c8782 from '../../locale/no.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"no","numberFormats":{"no":{"currency":{"style":"currency","currency":"NOK","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"code":"no","file":"no.json","iso":"no","defaultCurrency":"NOK"}],
  defaultLocale: "no",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "/app/locale/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: true,
  baseUrl: "https://seatronic.staging.a.wemade.no",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  country: "NO",
  normalizedLocales: [{"code":"no","file":"no.json","iso":"no","defaultCurrency":"NOK"}],
  localeCodes: ["no"],
}

export const localeMessages = {
  'no.json': () => Promise.resolve(localee32c8782),
}
