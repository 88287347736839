import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _565e6ef0 = () => interopDefault(import('../_templates/pages/checkout/Cart.vue' /* webpackChunkName: "" */))
const _17ea2fae = () => interopDefault(import('../_templates/pages/checkout/Checkout.vue' /* webpackChunkName: "" */))
const _85032ca8 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Account.vue' /* webpackChunkName: "" */))
const _445ec7e6 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Order.vue' /* webpackChunkName: "" */))
const _f4a19334 = () => interopDefault(import('../_templates/pages/checkout/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _23d93240 = () => interopDefault(import('../_templates/pages/search-loop54/Search.vue' /* webpackChunkName: "" */))
const _34b9f6c8 = () => interopDefault(import('../_templates/pages/customer/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _82c7281a = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _5fc376c6 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _b288ab3e = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _b670e826 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _042f028d = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _309311a1 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _da527a9c = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _e0ded86a = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollection.vue' /* webpackChunkName: "" */))
const _4b2e4004 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollectionItem.vue' /* webpackChunkName: "" */))
const _1a8d21dc = () => interopDefault(import('../_templates/pages/faq/Faq.vue' /* webpackChunkName: "" */))
const _b49d7dec = () => interopDefault(import('../pages/payment-svea/CheckoutConfirmation.vue' /* webpackChunkName: "" */))
const _4ea1abce = () => interopDefault(import('../_templates/pages/payment-svea/Checkout.vue' /* webpackChunkName: "" */))
const _6dc23e6e = () => interopDefault(import('../_templates/pages/blog/BlogPost.vue' /* webpackChunkName: "" */))
const _0bb78cae = () => interopDefault(import('../_templates/pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _0b49a8a6 = () => interopDefault(import('../_templates/pages/theme/Page.vue' /* webpackChunkName: "" */))
const _89d6da14 = () => interopDefault(import('../_templates/pages/theme/Home.vue' /* webpackChunkName: "" */))
const _68ecfb84 = () => interopDefault(import('../pages/catalog/Category.vue' /* webpackChunkName: "pages/catalog/Category" */))
const _a2ca8496 = () => interopDefault(import('../pages/catalog/OldCategory.vue' /* webpackChunkName: "pages/catalog/OldCategory" */))
const _1820d95f = () => interopDefault(import('../pages/catalog/Product.vue' /* webpackChunkName: "pages/catalog/Product" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart",
    component: _565e6ef0,
    name: "checkout-cart"
  }, {
    path: "/checkout",
    component: _17ea2fae,
    name: "checkout",
    children: [{
      path: "konto",
      component: _85032ca8,
      name: "checkout-account"
    }, {
      path: "bestilling",
      component: _445ec7e6,
      name: "checkout-order"
    }, {
      path: "takk",
      component: _f4a19334,
      name: "checkout-thank-you"
    }]
  }, {
    path: "/search",
    component: _23d93240,
    name: "search"
  }, {
    path: "/customer/reset-password",
    component: _34b9f6c8,
    alias: "/customer/account/createPassword",
    name: "reset-password"
  }, {
    path: "/customer",
    component: _82c7281a,
    meta: {"titleLabel":"Account"},
    name: "customer",
    children: [{
      path: "my-profile",
      component: _5fc376c6,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile"
    }, {
      path: "addresses-details",
      component: _b288ab3e,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details"
    }, {
      path: "addresses-details/create",
      component: _b670e826,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _042f028d,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit"
    }, {
      path: "order-history",
      component: _309311a1,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history"
    }, {
      path: "order-history/:orderId",
      component: _da527a9c,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order"
    }]
  }, {
    path: "/collection/:slug([^/.]+).html",
    component: _e0ded86a,
    name: "attributeCollection"
  }, {
    path: "/collection/:collection_slug/:slug+.html",
    component: _4b2e4004,
    name: "attributeCollectionItem"
  }, {
    path: "/faq.html",
    component: _1a8d21dc,
    name: "faq"
  }, {
    path: "/sveacheckout/confirmation",
    component: _b49d7dec,
    name: "sveaCheckoutConfirmation"
  }, {
    path: "/sveacheckout",
    component: _4ea1abce,
    name: "sveaCheckout"
  }, {
    path: "/magasinet/:slug+",
    component: _6dc23e6e,
    name: "blogPost"
  }, {
    path: "/magasinet",
    component: _0bb78cae,
    name: "blog"
  }, {
    path: "/:slug+",
    component: _0b49a8a6,
    name: "page"
  }, {
    path: "/",
    component: _89d6da14,
    name: "home"
  }, {
    path: "/catalog/Category",
    component: _68ecfb84,
    name: "catalog-Category"
  }, {
    path: "/catalog/OldCategory",
    component: _a2ca8496,
    name: "catalog-OldCategory"
  }, {
    path: "/catalog/Product",
    component: _1820d95f,
    name: "catalog-Product"
  }, {
    path: "/payment-svea/CheckoutConfirmation",
    component: _b49d7dec,
    name: "payment-svea-CheckoutConfirmation"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
